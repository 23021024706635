/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Billings = () => import("@/views/billings/index.vue");
/* Authenticated Component */

export default [
    {
        path: "/",
        component: Layout,
        // alwaysShow: true, // will always show the root menu
        // meta: {
        //     title: "Billings",
        //     icon: "lock",
        //     roles: ["admin", "editor"], // you can set roles in root nav
        // },
        children: [
            {
                name: "billings",
                path: "billings",
                component: Billings,
                meta: {
                    title: "Billings",
                    elSvgIcon: "Fold",
                    affix: true,
                    roles: ["super-admin", "normal-user"],
                },
            },
        ],
    },
];
