//复制文本
import useClipboard from "vue-clipboard3";
import { ElMessage } from "element-plus";

// i18n language  match title
import { i18n } from "@/lang";
// the keys using  zh file
import langEn from "@/lang/en";
import settings from "@/settings";
import { upperFirst, includes } from "lodash-es";

export const sleepTimeout = (time) => {
    return new Promise((resolve) => {
        const timer = setTimeout(() => {
            clearTimeout(timer);
            resolve(null);
        }, time);
    });
};

//深拷贝
export function cloneDeep(value) {
    return JSON.parse(JSON.stringify(value));
}

//copyValueToClipboard
const { toClipboard } = useClipboard();
export const copyValueToClipboard = (value) => {
    toClipboard(value);
    ElMessage.success(langTitle("copied_success"));
};
const { t, te } = i18n.global;
export const langTitle = (title) => {
    if (!title) {
        return settings.title;
    }
    for (const key of Object.keys(langEn)) {
        if (te(`${key}.${title}`) && t(`${key}.${title}`)) {
            return t(`${key}.${title}`);
        }
    }

    return title;
};

//get i18n instance
export const getLangInstance = () => {
    return i18n.global;
};

export const textUpperFirst = (word) => {
    return upperFirst(word);
};

export const getMethodData = (data) => {
    let newArr = {};
    data.forEach((item, index) => {
        if (item["form"]) {
            newArr[item.field] = item.value;
            // } else {
            //     if (
            //         method == "edit" &&
            //         (item["form"] || item["field"] == "index")
            //     ) {
            //         newArr[item.field] = item.value;
            //     }
        }
    });

    return newArr;
};

export const setSchemaData = (schemas, data) => {
    schemas.forEach((item, index) => {
        if (item.form && data[item.field] != undefined) {
            if (item.component == "Checkbox") {
                if (item.field == "permission") {
                    let checkboxItems = data[item.field].split(",");
                    let checkboxArr = [];
                    checkboxItems.forEach((subItem, subIndex) => {
                        checkboxArr.push(
                            subItem.substring(0, subItem.indexOf("-"))
                        );
                    });
                    item.value = checkboxArr;
                } else {
                    item.value = data[item.field].split(",");
                }
            } else {
                item.value = data[item.field];
            }
        } else if (item.component == "Tree") {
            item.defaultCheckKeys = data["permissions-ids"];
        } else {
            if (item.field == "index" && data["id"] != undefined) {
                item.value = data["id"];
            }
        }
    });

    return schemas;
};

export const getRules = (schemas) => {
    let newArr = {};
    schemas.forEach((item, index) => {
        if (item.rules != undefined) {
            newArr[item.field] = item.rules;
        }
    });

    return newArr;
};

export const setRules = (rules, errors) => {
    Object.keys(rules).forEach((item, index) => {
        if (errors[item] != undefined) {
            rules[item][0]["required"] = true;
            rules[item][0]["message"] = errors[item][0];
        } else {
            rules[item][0]["required"] = false;
            rules[item][0]["message"] = "";
        }
    });

    return rules;
};

export const resetFormData = (data, originalRules) => {
    data.forEach((item, index) => {
        if (item.form) {
            item.value = includes(["Checkbox"], item.component) ? [] : "";
            if (originalRules[item.field] != undefined) {
                item.rules[0].required = originalRules[item.field].required;
                // item.rules[0].required = true;
                item.rules[0].message = "";
            }
        }
    });

    return data;
};

export const showMessage = (type, message) => {
    const duration = 2000;
    ElMessage({
        type: type,
        message: message,
        duration: duration,
    });
};
