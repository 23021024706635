/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Settings = () => import("@/views/settings/Index.vue");
const Email = () => import("@/views/settings/Email.vue");
/* Authenticated Component */

export default [
    {
        path: "/settings",
        component: Layout,
        meta: { title: "Settings", icon: "password", roles: ["super-admin"] },
        children: [
            {
                name: "setting",
                path: "setting",
                component: Settings,
                meta: { title: "Index" },
            },
            {
                name: "setting-email",
                path: "setting-email",
                component: Email,
                meta: { title: "Email" },
            },
        ],
    },
];
