/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Companies = () => import("@/views/companies/Index.vue");
/* Authenticated Component */

export default [
    {
        path: "/",
        component: Layout,
        children: [
            {
                name: "companies",
                path: "companies",
                component: Companies,
                meta: {
                    title: "Companies",
                    elSvgIcon: "OfficeBuilding",
                    affix: true,
                    roles: ["super-admin"],
                },
            },
        ],
    },
];
