/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Registrations = () => import("@/views/registrations/Index.vue");
const Events = () => import("@/views/events/Index.vue");
/* Authenticated Component */

export default [
    {
        path: "/event",
        component: Layout,
        meta: { title: "Event", icon: "list", roles: ["super-admin", 'normal-user'] },
        // alwaysShow: true,
        children: [
            {
                name: "events",
                path: "events",
                component: Events,
                meta: { title: "Events" },
            },
            {
                name: "event-registers",
                path: "event-registers",
                component: Registrations,
                meta: { title: "Registrations" },
            },
        ],
    },
];
